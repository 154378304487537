/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { theme } from '../constants/theme';
import useBodyScrollLock from '../hooks/useBodyScrollLock';

const Drawer = ({ children, open, onMaskClick }) => {
  const maskAnimations = useSpring({
    opacity: open ? 1 : 0,
  });

  const drawerRef = useRef(null);

  useBodyScrollLock({
    locked: open,
    ref: drawerRef,
  });

  const contentAnimations = useSpring({
    zIndex: 10,
    transform: open ? 'translate(0px,0)' : 'translate(100vw,0)',
  });

  return (
    <DrawerContainer>
      <DrawerMask style={maskAnimations} open={open} onClick={onMaskClick} />
      <DrawerContent style={contentAnimations} open={open} ref={drawerRef}>
        <DrawerContentContainer>
          {children}
        </DrawerContentContainer>
      </DrawerContent>
    </DrawerContainer>
  );
};

const DrawerContainer = styled.div`
  overflow-y: hidden;
`;

const DrawerMask = styled(animated.div)`
  background-color: rgba(252, 5, 5, 0.8);
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${({ open }) => (open ? 'block' : 'none')}

`;

const DrawerContentContainer = styled.div`
  position: relative;
  height: 100%;
  z-index: 100;
`;

const DrawerContent = styled(animated.div)`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  width: 81%;
  z-index: 99;

  @media ${theme.breakpoints.size.s} {
    width: 100%;
  }
`;

export default Drawer;
