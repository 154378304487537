import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';

import { theme } from '../constants/theme';
import { linkResolver } from '../utils/linkResolver';

const InfoSection = ({ content, title }) => (
  <Section>
    <SectionTitle>
      {title}
    </SectionTitle>
    <SectionContent>
      {RichText.render(content, linkResolver)}
    </SectionContent>
  </Section>
);

export const PeopleSection = ({
  people, title, setSelected, noRule,
}) => (
  <Section className="nominations" noRule={noRule}>
    <SectionTitle className="nominations_title">
      {title}
    </SectionTitle>
    <PeopleList>
      {people && people.map((person) => (
        <PeopleListItem>
          <CollaboratorButton onClick={() => setSelected(person)}>
            <ContributorName>{person.name}</ContributorName>
            {person.job_title && <p>{person.job_title}</p>}
            <p>{person.location}</p>
          </CollaboratorButton>
        </PeopleListItem>
      ))}
    </PeopleList>
  </Section>
);

const ContributorName = styled.p`
  border-bottom: 1px solid ${theme.colors.red};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;
  display: inline;
  cursor: pointer;
`;

const CollaboratorButton = styled.button`
  display: inline;
  text-align: left;
  @media ${theme.breakpoints.size.s} {
    text-align: center;
  }
`;

const Section = styled.div`
  border-top: ${({ noRule }) => (noRule ? 'none' : '1px solid #F50000')};
  display: flex;

  @media ${theme.breakpoints.size.s} {
    flex-direction: column;
  }

  &.nominations {
    flex-direction: column;
  }
`;

const SectionTitle = styled.h2`
  color: ${theme.colors.red};
  display: flex;
  flex: 1;
  font-size: 30px;
  font-weight: 400;
  flex-direction: column;
  text-transform: uppercase;
  margin-top: 15px;

  @media ${theme.breakpoints.size.m} {
    margin-bottom: 100px;
  }

  @media ${theme.breakpoints.size.m} {
    font-size: 25px;
    margin-top: 10px;
  }

  @media ${theme.breakpoints.size.s} {
    text-align: center;
    margin-bottom: 10px;
  }

  &.nominations_title {
    margin: 15px 0 39px;
    @media ${theme.breakpoints.size.m} {
      margin: 12px 0 37px;
    }

    @media ${theme.breakpoints.size.s} {
      margin: 12px 0 10px;
      text-align: center;
    }
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 125px;
  font-size: 18px;
  flex: 1;
  line-height: 28px;
  margin-right: 40px;
  margin-left: 80px;

  @media ${theme.breakpoints.size.xl} {
    margin-right: 0px;
    margin-left: 40px;
  }

  @media ${theme.breakpoints.size.l} {
    flex: 2;
    max-width: 58%;
  }

  @media ${theme.breakpoints.size.m} {
    margin-bottom: 70px;
    flex: 2;
    max-width: 64%;
  }

  @media ${theme.breakpoints.size.s} {
    max-width: 100%;
    margin-left: 0px;
    text-align: center;
  }

  a:hover {
    color: ${theme.colors.red};
  }

`;

const PeopleList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 50px;
  column-gap: 50px;

  @media ${theme.breakpoints.size.m} {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    column-gap: 50px;
  }

  @media ${theme.breakpoints.size.s} {
    display: flex;
    grid-template-columns: 1;
  }
`;

const PeopleListItem = styled.li`
  margin-bottom: 41px;
  p {
    text-align: left;
    line-height: 28px;
    margin: 0;
    @media ${theme.breakpoints.size.s} {
      text-align: center;
    }
  }

  @media ${theme.breakpoints.size.m} {
    margin: 0px 0px 50px;
    width: 50%;
  }

  @media ${theme.breakpoints.size.s} {
    text-align: center;
    width: 100%;
    margin: 0px 0px 25px;

  }
`;

export default InfoSection;
