import styled from 'styled-components';
import { theme } from '../constants/theme';

const PageDescription = styled.div`
  color: black;
  display: block;
  font-size: 35px;
  line-height: 45px;
  font-weight: 400px;

  color: black;
  margin: 38px auto 140px;
  text-align: center;
  width: 58%;
  padding: 0;

  p {
    margin: 0;
  }

  @media ${theme.breakpoints.size.xl} {
    width: 62%;
    font-size: 28px;
    line-height: 35px;
    padding: 0 50px;
  }

  @media ${theme.breakpoints.size.l} {
    width: 70.5%;
    padding: 0 30px;
    margin: 43px auto 139px;
  }

  @media ${theme.breakpoints.size.m} {
    width: 73%;
    font-size: 25px;
    line-height: 32px;
    margin: 25px auto 89px;
    padding: 0;
  }

  @media ${theme.breakpoints.size.s} {
    width: 90%;
    margin: 20px auto 89px;
    padding: 0 0;
  }
`;

export default PageDescription;
