import React from 'react';
import styled from 'styled-components';

import { RedClose } from '../images/redClose';
import { RightArrow } from '../images/rightArrow';
import { LeftArrow } from '../images/leftArrow';
import PersonDetail from './PersonDetail';
import Drawer from './Drawer';
import { theme } from '../constants/theme';

const PersonDrawer = ({
  hideNavigation,
  onNext,
  onPrevious,
  selectedCollaborator,
  onClose,
}) => (
  <Drawer open={selectedCollaborator} onMaskClick={onClose}>
    {selectedCollaborator && (
      <>
        <CollaboratorNav>
          <CloseButton onClick={onClose}><RedClose /></CloseButton>
          <div style={{ flex: 1 }} />
          { !hideNavigation && (
            <Controls>
              <button type="button" onClick={onPrevious}>
                <LeftArrow />
              </button>
              <button type="button" onClick={onNext}>
                <RightArrow />
              </button>

            </Controls>
          )}
        </CollaboratorNav>
        <Scroller>
          <PersonContainer>
            <PersonDetail
              name={selectedCollaborator.name}
              homepage={selectedCollaborator.homepage_link}
              job_title={selectedCollaborator.job_title}
              info={selectedCollaborator.location}
              image={selectedCollaborator.image}
              biography={selectedCollaborator.biography}
            />
          </PersonContainer>
        </Scroller>
      </>
    )}
  </Drawer>
);

const Scroller = styled.div`
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const PersonContainer = styled.div`
  text-align: left;
  margin: 0px 80px 30px;
  display: inline;

  @media ${theme.breakpoints.size.m} {
    margin: 0px 40px;
  }
  @media ${theme.breakpoints.size.s} {
    margin: 0px 20px;
  }
`;

const CloseButton = styled.button`
  svg {
    width: 18px;
  }
`;

const Controls = styled.div`
  align-self: flex-end;
  button {
    margin-left: 25px;
  }
`;

const CollaboratorNav = styled.div`
  text-align: left;
  margin: 26px 80px 0 80px;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  @media ${theme.breakpoints.size.m} {
    margin: 20px 40px 0 40px;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 20px 20px 0 20px;
  }
`;

export default PersonDrawer;
