import styled from 'styled-components';
import { theme } from '../constants/theme';

const PageHeader = styled.div`
  color: ${theme.colors.red};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;

  h1 { text-align: center; }
`;

export default PageHeader;
