import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import Imgix from 'react-imgix';

import { theme } from '../constants/theme';

const PersonDetail = ({
  name, info, image, biography, homepage,
}) => (
  <PersonDetailContainer>
    <Header>
      <Name>{name}</Name>
      <p>{info}</p>
    </Header>
    <Holder>
      <Bio>
        { image && (
          <a href={homepage && homepage.url} target="_blank" rel="noreferrer" style={{ flex: '2' }}>
            <Image>
              <Imgix
                className="lazyload"
                src={image.url}
                sizes="100vw"
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
                key={name}
                alt={image.alt}
                htmlAttributes={{
                  src: `${image.url}&blur=800&auto=format&w=600`,
                }}
            />
            </Image>
          </a>
        )}
        <BioText>
          {RichText.render(biography)}
        </BioText>
      </Bio>
    </Holder>
  </PersonDetailContainer>
);

const Holder = styled.div`
  margin: 0px 80px;

  @media ${theme.breakpoints.size.l} {
    margin: 0px 40px;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 0px 20px;
  }
`;

const Header = styled.div`
  color: ${theme.colors.red};
  margin: 0px 80px;

  @media ${theme.breakpoints.size.l} {
    margin: 0px 40px;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 0px 20px;
  }
  @media ${theme.breakpoints.size.s} {
    p {
      text-align: center;
      margin: 7px 20px 32px;
    }
  }

  p {
    margin: 0 0px;
    color: black;
    font-size: 18px;
    line-height: 28px;

    @media ${theme.breakpoints.size.s} {
      margin: 15px 0px;
    }
  }
`;

const PersonDetailContainer = styled.div`
  padding-bottom: 40px;
`;

const Name = styled.h1`
  color: ${theme.colors.red};
  font-size: 100px;
  font-weight: 400;
  margin: 0;
  margin-left: -4px;
  text-transform: uppercase;
  line-height: 1;
  @media ${theme.breakpoints.size.l} {
    font-size: 68px;
  }
  @media ${theme.breakpoints.size.s} {
    font-size: 45px;
    text-align: center;
  }
`;

const Bio = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  @media ${theme.breakpoints.size.s} {
    flex-direction: column;
    margin-top: 0px;
  }

  a {
    flex: 6;

    @media ${theme.breakpoints.size.s} {
      margin: 0 0 35px 0;
      text-align: center;
      p {
        text-align: center;
      }
    }
  }
`;

const Image = styled.div`
  margin-top: 4px;
  img {
    max-width: 100%;
    width: 100%;
  }
  @media ${theme.breakpoints.size.s} {
    padding: 0 35px;
  }
`;

const BioText = styled.div`
  width: 51.7%;
  padding: 0 40px 0px 80px;

  @media ${theme.breakpoints.size.xl} {
    font-size: 68px;
    padding: 0 0px 0px 80px;
  }

  @media ${theme.breakpoints.size.l} {
    padding: 0 0px 0px 50px;
  }

  @media ${theme.breakpoints.size.s} {
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 0;
  }

  p {
    font-family: "fakt";
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 70px;
  }

  a:hover {
    color: ${theme.colors.red};
  }
`;
export default PersonDetail;
