/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { useLocation } from '@reach/router';

import PageTitle from '../components/PageTitle';
import PageHeader from '../components/PageHeader';
import PageDescription from '../components/PageDescription';
import InfoSection from '../components/InfoSection';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { theme } from '../constants/theme';
import PersonDrawer from '../components/PersonDrawer';

export const query = graphql`
  query Abouts($id: String) {
    prismic {
      allAbouts(id: $id) {
        edges {
          node {
            body {
              ... on PRISMIC_AboutBodyDirector {
                type
                label
                primary {
                  name
                }
                fields {
                  location
                }
              }
            }
            collaborator_title
            directors_title
            header_copy
            section_copy
            section_title
            title
            body1 {
              ... on PRISMIC_AboutBody1Collaborator {
                type
                label
                primary {
                  homepage_link {
                    ... on PRISMIC__ExternalLink {
                      target
                      url
                      _linkType
                    }
                  }
                  job_title
                  biography
                  location
                  name
                  image
                }
              }
            }
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
    }
  }
`;

const slugify = (text) => text.toString().toLowerCase()
  .replace(/\s+/g, '-') // Replace spaces with -
  .replace(/[^\w-]+/g, '') // Remove all non-word chars
  .replace(/--+/g, '-') // Replace multiple - with single -
  .replace(/^-+/, '') // Trim - from start of text
  .replace(/-+$/, ''); // Trim - from end of text

const About = ({ data, location }) => {
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const page = data.prismic.allAbouts.edges[0];
  const { pathname, hash } = useLocation();

  const onNext = useCallback(() => {
    const {
      body1: collaborators,
    } = page.node;

    const index = collaborators.findIndex((item) => item.primary.name === selectedCollaborator.name);

    if (index === collaborators.length - 1) {
      setSelectedCollaborator(collaborators[0].primary);
    }

    setSelectedCollaborator(collaborators[index + 1].primary);
  }, [selectedCollaborator, page]);

  const onPrevious = useCallback(() => {
    const {
      body1: collaborators,
    } = page.node;

    const index = collaborators.findIndex((item) => item.primary.name === selectedCollaborator.name);

    if (index === 0) {
      setSelectedCollaborator(collaborators[collaborators.length + 1].primary);
    }

    setSelectedCollaborator(collaborators[index - 1].primary);
  }, [selectedCollaborator, page]);

  const onClose = useCallback(() => {
    setSelectedCollaborator(undefined);
    window.history.pushState({}, null, `${pathname}`);
  }, [pathname, setSelectedCollaborator]);

  useEffect(() => {
    if (page || page.node) {
      const {
        body1: collaborators,
      } = page.node;

      if (hash) {
        const match = collaborators.find((person) => `#${slugify(person.primary.name)}` === hash);
        setSelectedCollaborator(match.primary);
      }
    }
  }, [hash, page]);

  useEffect(() => {
    if (selectedCollaborator) {
      const newHash = slugify(selectedCollaborator.name);
      window.history.pushState({}, null, `${pathname}#${newHash}`);
    }
  }, [hash, selectedCollaborator, pathname]);

  if (!page || !page.node) {
    return null;
  }

  const {
    body: directors,
    body1: collaborators,
    collaborator_title,
    directors_title,
    title,
    header_copy,
    section_title,
    section_copy,
    _meta: { lang },
  } = page.node;

  return (
    <Layout lang={lang} location={location}>
      <SEO title="Home" />
      <PageHeader>
        <PageTitle mainTitleText={title} />
        <PageDescription>{RichText.render(header_copy)}</PageDescription>
      </PageHeader>

      <AboutPageContent className="page">
        <InfoSection content={section_copy} title={section_title} />
        <PeopleSection>
          <SectionHeading className="directors">{directors_title}</SectionHeading>
          <PeopleList>
            {directors && directors.map((person) => (
              <PeopleListItem>
                <DirectorName>{person.primary.name}</DirectorName>
                {person && person.fields.map((field) => (<p>{field.location}</p>))}
              </PeopleListItem>
            ))}
          </PeopleList>
        </PeopleSection>
        <PeopleSection>
          <SectionHeading>{collaborator_title}</SectionHeading>
          <PeopleList>
            {collaborators && collaborators.map((person) => (
              <PeopleListItem key={person.primary.name}>
                <CollaboratorButton onClick={() => setSelectedCollaborator(person.primary)}>
                  <ContributorName>{person.primary.name}</ContributorName>
                  <p>{person.primary.job_title}</p>
                  <p>{person.primary.location}</p>
                </CollaboratorButton>
              </PeopleListItem>
            ))}
          </PeopleList>
        </PeopleSection>
      </AboutPageContent>
      <PersonDrawer
        slug={selectedCollaborator && slugify(selectedCollaborator.name)}
        onClose={onClose}
        selectedCollaborator={selectedCollaborator}
        setSelectedCollaborator={setSelectedCollaborator}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </Layout>
  );
};

const CollaboratorButton = styled.button`
  display: inline;
  text-align: left;
  @media ${theme.breakpoints.size.s} {
    text-align: center;
  }
`;

const PeopleList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 50px;
  column-gap: 50px;

  @media ${theme.breakpoints.size.m} {
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    column-gap: 50px;
  }

  @media ${theme.breakpoints.size.s} {
    display: flex;
    grid-template-columns: 1;
  }
`;

const PeopleListItem = styled.li`
  margin-bottom: 41px;
  p {
    text-align: left;
    line-height: 28px;
    margin: 0;
    @media ${theme.breakpoints.size.s} {
      text-align: center;
    }
  }

  @media ${theme.breakpoints.size.m} {
    margin: 0px 0px 50px;
    width: 50%;
  }

  @media ${theme.breakpoints.size.s} {
    text-align: center;
    width: 100%;
    margin: 0px 0px 25px;

  }
`;

const DirectorName = styled.p`
  color: ${theme.colors.red};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;
`;
const ContributorName = styled.p`
  border-bottom: 1px solid ${theme.colors.red};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;
  display: inline;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.red};
  }
`;

const PeopleSection = styled.div`
  border-top: 1px solid ${theme.colors.red};
`;

const SectionHeading = styled.h2`
  color: ${theme.colors.red};
  text-transform: uppercase;
  margin: 15px 0 62px;
  font-size: 30px;
  font-weight: 400;

  &.directors {
    margin: 15px 0 52px;

    @media ${theme.breakpoints.size.xl} {
      margin: 15px 0 62px;
    }

    @media ${theme.breakpoints.size.m} {
      margin: 15px 0 42px;
    }

    @media ${theme.breakpoints.size.s} {
      margin: 12px 0 27px;
    }
  }

  @media ${theme.breakpoints.size.m} {
    font-size: 25px;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 12px 0 27px;
    text-align: center;
  }
`;

const AboutPageContent = styled.div`
  margin: 0 80px 0px;

  @media ${theme.breakpoints.size.l} {
    margin: 0 40px;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 0 20px;
    padding-bottom: 40px;
  }
`;

export default About;
